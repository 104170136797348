import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail-sk.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Content of this field is too short")
    .max(50, "Content of this field is too long")
    .required("Toto pole je povinné"),
  surname: Yup.string()
    .min(2, "Content of this field is too short")
    .max(50, "Content of this field is too long")
    .required("This field is required"),
  email: Yup.string()
    .min(2, "Content of this field is too short")
    .max(50, "Content of this field is too long")
    .email("Not a proper e-mail address format")
    .required("This field is required"),
  phone: Yup.string()
    .min(5, "Content of this field is too short")
    .max(15, "Content of this field is too long"),
  privacyEbook: Yup.bool().oneOf([true], "Acceptance required."),
})

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      surname: "",
      email: "",
      company: "",
      position: "",
      phone: "",
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }


  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            surname: "",
            email: "",
            company: "",
            position: "",
            phone: "",
            privacy: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <div className="grid md:grid-cols-3 gap-5 my-10">
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none shadow-md" type="text" name="name" placeholder="Meno*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="name"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="surname" placeholder="Priezvisko*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="surname"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="email" placeholder="Služobná e-mailová adresa*"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="email"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="company" placeholder="Firma"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="company"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="position" placeholder="Pozícia"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="position"
                      component="span"
                    />
                </div>
                <div className="">
                  <Field className="border-2 border-purple rounded-xl w-full px-5 py-3 text-dark-gray placeholder:text-light-gray outline-none" type="text" name="phone" placeholder="Telefon"/>
                  <ErrorMessage
                      className="error-msg text-xs"
                      name="phone"
                      component="span"
                    />
                </div>
                </div>
                <div className="privacy-policies">
                <div>
                  <label
                    className="text-dark-gray relative pl-10 block text-sm mb-5"
                    htmlFor="privacyEbook"
                  >
                      Súhlasím so spracovaním mojich osobných údajov spoločnosťou Antal s.r.o. so sídlom vo Varšave (02-566), ul. Puławska 2, za účelom marketingu prostredníctvom elektronických komunikačných prostriedkov (email, telefón). Súhlas je možné kedykoľvek odvolať.*
                  <Field
                    type="checkbox"
                    id="privacyEbook"
                    name="privacyEbook"
                    className="absolute left-0 top-0"
                  />
                    <ErrorMessage
                      className="error-msg text-xs"
                      name="privacyEbook"
                      component="span"
                    />
                  </label>
                </div>
                <div className="text-dark-gray relative pl-10 block text-sm">
                    Povinné
                    <div className="absolute left-0 top-0 text-xl">*</div>
                </div>
              </div>
              <div className="flex justify-center py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold w-80 px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                  Stiahnuť e-book
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Ďakujeme!</h3>
          <p className="text-xl mb-12">Tvoja správa bola odoslaná.</p>
          <a href="/static/Antal_Raport_Trendy_2022_EN_v01.pdf" className="bg-teal text-dark-gray text-sm font-bold w-80 px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
              Stiahnuť e-book
              </a>
        </form>
      )
    }
  }
}