import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqItems = [
    {
        question: "Kto je ReX Recruiter?",
        answer: 'Je to profesionálny nezávislý náborový pracovník freelancer alebo personálna spoločnosť. ReX Recruiteri prechádzajú overovacím a tréningovým výberovým procesom, aby sa mohli stať naším partnerom. Po podpise zmluvy a skúškach v oblasti spracovania kandidátov a GDPR. Rex Recruiteri podporujú náborové procesy na crowdstaffingovej platforme ReX.',
    },
    {
        question: "Kto je ReX Partner?",
        answer: 'Rex Partner a ReX Recruiter sú často identické pojmy v zmluvách a v systéme ich rozlišujeme medzi osobou alebo spoločnosťou (ReX Partner), ktorá je zmluvnou stranou, a recruiterom (ReX Rekruter), ktorý skutočne robí nábor.',
    },
    {
        question: "Kto je konzultant?",
        answer: 'Konzultant je zamestnanec Antal, ktorý je zodpovedný za spracovanie kandidáta v súlade so zmluvou uzatvorenou s klientom.',
    },
    {
        question: "Kto je ReX Leader?",
        answer: 'REX Leader je osoba, ktorá riadi projekty v oblasti danej špecializácie. Na strane klienta je zodpovedný za komunikáciu a vhodný výber Rex Recruiterov pre realizáciu projektu. Na strane platformy má podporovať ReX Recruiterov a správne im odporúčať projekty v súlade s ich profilom.',
    },
    {
        question: "Kto je Key Account Manager?",
        answer: 'Key Account Manager (KAM) je odborník v oblasti recruitmentu v danej oblasti, osoba určená na obsluhu klienta a kandidáta na základe objednávky, ktorá za príplatok poskytuje konzultačné, poradenské služby, overovanie žiadostí. a koordinácia spolupráce pre daného klienta.',
    },
    {
        question: "Crowdstaffing – ako to funguje?",
        answer: 'Úlohou davu je reagovať na potreby jednotlivca alebo firmy. Vďaka tomu, že viacero špecializovaných ľudí hľadá kandidátov v geografickom rozptyle s využitím rôznych nástrojov a rôznych databáz súčasne, dokážu rýchlo a efektívne zrealizovať náborový proces.',
    },
    {
        question: "Ako vyzerá proces zadávania objednávky?",
        answer: 'Keď dostaneme popis práce, vieme predložiť cenovú ponuku, po jej schválení klientom je nami vyplnený objednávkový dokument odoslaný cez portál elektronického podpisu na schválenie oboma stranami.',
    },
    {
        question: "Musím sa zaregistrovať na platforme?",
        answer: 'Nie. Ponuku, popis práce a akékoľvek ďalšie údaje môžete zaslať pomocou formulára alebo e-mailom karolina@recruitmentexchange.pl',
    },
    {
        question: "Čo obdržím?",
        answer: 'Klient spolu s priloženým životopisom dostane profily vybraných kandidátov s kontaktnými údajmi, finančnými požiadavkami, výpovednou lehotou, preferovanou formou spolupráce daného kandidáta, ich lokalizáciou a popisným odporúčaním recruitera. Odporúčanými kandidátmi sú len záujemcovia o pohovor k danej ponuke, ktorí sú oboznámení s pozíciou.',
    },
    {
        question: "Aká je cena? Kedy prebieha platba?",
        answer: 'Suma za úspech je rovnaká bez ohľadu na plat kandidáta. Odhadovanú cenovú ponuku si môžete skontrolovať u nás na stránke, no overujeme si ju v kontexte aktuálnej situácie na trhu, potenciálu kandidátov a primeranosti požiadaviek k potenciálu zamestnania. Faktúru vystavujeme v deň nástupu uchádzača do práce so 14-dňovou lehotou splatnosti.',
    },
    
];

const Faq = () => {
  return (
      <div className="overflow-hidden">
    <section id="faq" className="faq pt-0 pb-24 bg-gradient-to-br from-purple to-teal">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl text-center text-white font-bold mb-20">Často kladené otázky</h2>
        <Accordion allowZeroExpanded="true">
            {FaqItems.map((faqItem) => (
        <AccordionItem className="mb-10">
          <AccordionItemHeading className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
            <AccordionItemButton className="faq-button relative">
              {faqItem.question}
                <div className="arrow border-b-purple border-l-purple w-3 h-3 absolute right-0 top-0 border-[0.35rem] border-r-transparent border-t-transparent -rotate-45 transition-all"></div>
            </AccordionItemButton> 
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white px-8 py-8  text-dark-gray text-sm">
            {faqItem.answer}
          </AccordionItemPanel>
        </AccordionItem>
            ))}
            <div className="mb-10">
                <div className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
                    <div className="relative flex flex-col md:flex-row justify-between items-center">
                        <h3 className="text-2xl basis-1 mr-4">Máte&nbsp;otázku?</h3>
                        <div className="bg-gradient-to-r from-purple to-teal basis-full h-[2px] my-4"></div>
                        <a href="mailto:robert.nawrot@recruitmentexchange.pl" className="ml-4 basis-1 bg-teal text-dark-gray text-sm font-bold  px-24 pt-3  py-2 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                            Napíšte&nbsp;nám
                        </a>
                    </div>
                </div>
            </div>

        
      </Accordion>
      </div>
    </section>
      </div>
  )}

  export default Faq