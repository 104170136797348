import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LogoRex from "../../images/logo-rex.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Examples = () => {
    let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                  },
              },
              ]
    };


  return (
    <section  className="examples relative pt-52 pb-10 bg-center -mt-40" id="achievements">
        <div className="absolute bg-light-pink w-full left-0 h-1/2 bottom-0">

        </div>
        <div className="max-w-7xl mx-auto mt-40 md:mt-20 ">
            <h2 className="text-white font-bold text-3xl mb-12" >Príklady<br/> realizovaných projektov </h2>
            <p className="text-white mb-0 "  >ReX Recruiteri vykonávajú aj tie najnáročnejšie náborové procesy. V našej komunite sú odborníci, ktorí sa špecializujú na všetky odvetvia. Nájdeme vám zamestnanca bez ohľadu na to, či potrebujete 1, 10 alebo 100 ľudí do vášho tímu.</p>
            <Slider {...settings} className="relative -mx-10" >

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-k.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Junior účtovník</h3>
                        <p className="text-xs mb-6">Zodpovedá za mesačnú účtovnú závierku, komunikácia so zdravotným, sociálnym a daňovým úradom, mzdy, účtovníctvo zamestnancov, archiváciu dokumentácie, prácu s programom Płatnik a Progmann - Kadry, Przelewy.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu zvyčajne naberáme v Poznani, Vroclavi a Varšave za cca 7 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika - Junior účtovník - Náborová cena - 7 000 PLN</h3>
                        <p className="text-sm">Vedenie účtovníctva a riadenie ľudských zdrojov pre firmy s úplným a zjednodušeným účtovníctvom.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/anna.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Anna, CS with Slovenian</h3>
                        <p className="text-xs mb-6">Zodpovedá za komplexné vybavovanie objednávok a reklamácií internetového obchodu. Telefonický a e-mailový servis pre slovinských zákazníkov, poradenstvo ohľadom ponúkaného produktu.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu naberáme vo Varšave zvyčajne za cca 8 500 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Anna, CS with Slovenian - Náborová cena - 8500 zł</h3>
                        <p className="text-sm">E-mailový a telefonický kontakt so zákazníkmi, ktorí si zakúpili produkt.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/urszula.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">People Advisor </h3>
                        <p className="text-xs mb-6">Inak personalista. Zodpovednosť za celý cyklus zamestnávania zamestnanca a poradenstvo v oblasti pracovného práva.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu vo Varšave naberáme zvyčajne za približne 17 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Urszula – People Advisor – Náborová cena: 17 000 zł.</h3>
                        <p className="text-sm">Podpora zamestnancov v administratívnych a HR záležitostiach.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/adam.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Obchodný zástupca</h3>
                        <p className="text-xs mb-6">Zodpovednosť za plnenie targetu, starostlivosť o existujúcich zákazníkov a často aj získavanie nových zákazníkov.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu naberáme po celom Poľsku za približne 10 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Adam - Obchodný zástupca- Náborová cena: 10 000 PLN</h3>
                        <p className="text-sm">Predaj služieb spoločnosti a jej zastupovanie pri kontakte so zákazníkmi.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/ewa.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">CS with German</h3>
                        <p className="text-xs mb-6">Zodpovedá za zadávanie údajov o objednávkach do SAP, riešenie aktuálnych problémov zákazníkov, informovanie zákazníkov o stave ich objednávok.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu naberáme po celom Poľsku za približne 8 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Ewa, CS with German - Náborová cena - 8000 zł</h3>
                        <p className="text-sm">Telefonický a e-mailový kontakt s biznis klientmi ohľadom objednávok.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/tomek.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Customer Quality Engineer</h3>
                        <p className="text-xs mb-6">Zodpovedá za dohľad nad plnením požiadaviek zákazníkov v organizácii. Správa sťažností zákazníkov (8D reporty + sledovanie aktivít).Vypracovanie kvalitatívnych analýz a reportov. Príprava PPAP na zmeny v sériovej výrobe.</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu naberáme po celom Poľsku za približne 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Tomek, Customer Quality Engineer - Náborová cena -12 000 PLN</h3>
                        <p className="text-sm">Prvá línia obrany proti sťažnostiam zákazníkov.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-c.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">C# Developer</h3>
                        <p className="text-xs mb-6">Tvorba softvéru na vlastnú aktualizáciu údajov.
                            Zavádzanie aktualizácií softvéru podľa obchodných potrieb.
                            Kontakt s biznesom</p>
                        <p className="text-xs font-bold">Kandidátov na túto pozíciu naberáme po celom Poľsku za približne 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika - C# Developer - Náborová cena  - 25 000 zł</h3>
                        <p className="text-sm">Zostavenie výpočtového programu pre mechanické a hydraulické zaťaženia, ktoré prevládajú vo vrte.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

                
            </Slider>   
           
        </div>
    </section>    
  )
}

export default Examples