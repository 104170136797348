import * as React from "react"

const Header = () => {
  return (
    <header>
        <div className="py-1 bg-purple text-white">
            <div className="max-w-7xl mx-auto">
                <a href="https://join.recruitmentexchange.pl/" className=" text-xs ml-10">
                Mám k dizpozícii kandidátov – hladám projek &rarr;</a>
            </div>

        </div>
    </header>
  )
}

export default Header