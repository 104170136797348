import * as React from "react"
import '../styles/global.css'
import Header  from "../components/sk/header";
import Top from "../components/sk/top";
import About from "../components/sk/about";
import Benefits from "../components/sk/benefits";
import NoLimits from "../components/sk/no-limits";
import HowWeWork from "../components/sk/how-we-work";
import Clients from "../components/sk/clients";
import Testimonials from "../components/sk/testimonials";
import Examples from "../components/sk/examples";
import Download from "../components/sk/download";
import Team from "../components/sk/team";
import Leaders from "../components/sk/leaders";
import LeaderSupport from "../components/sk/leader-support"
import Faq from "../components/sk/faq";
import Footer from "../components/sk/footer";
import { StaticImage } from "gatsby-plugin-image"
import PriceComparison from "../components/sk/priceComparison";




const IndexPage = () => {
  return (
    <div>
      <Header />
      <div className="relative">
      <div className="x-container absolute h-full w-full object-left-top z-20">
            {/* <StaticImage className="inset-x-0" src={'../images/X-header.png'} alt="X" /> */}
        </div>
      <Top />
      <About />
      </div>
      <NoLimits />
      <HowWeWork />
      <PriceComparison />
      <Clients />
      <Testimonials />
      <Examples />
      <Download />
      <Leaders />
      {/* <LeaderSupport /> */}
      <Faq />
      <Footer />
    </div>
  )
}

export default IndexPage

export const Head = () => <title>REX | Recruitment Exchange powered by Antal</title>
