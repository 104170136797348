import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HowWeWork = () => {
  return (
    <section id="how-do-we-operate" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">Ako <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">fungujeme</span>?</h2>
            <div className="grid lg:grid-cols-3 gap-20 xl:-mx-20">
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">1</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Predloženie<br/> projektu klientom</h3>
                    <p className="font-light text-dark-gray text-md">Dbáme o váš čas, takže sa nemusíte nikde registrovať. Stačí, ak nám pošlete pracovnú náplň daného miesta a odpoviete na niekoľko jednoduchých otázok.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">2</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Výber recruiterov<br/> ktorí okamžite začínajú s jeho realizáciou.</h3>
                    <p className="font-light text-dark-gray text-md">Pracujú pre vás kvalifikovaní ReX Recruiteri, ktorí poznajú špecifiká vášho odvetvia a lokality, a preto vám poskytnú vhodných kandidátov.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">3</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Doručenie kandidátov<br/> a vyúčtovanie projektu</h3>
                    <p className="font-light text-dark-gray text-md">Už po niekoľkých dňoch vám doručíme prvé CV nájdených kandidátov. Platíte nám len vtedy, keď zamestnáte odporúčaného kandidáta. Podmienky spolupráce sú transparentné a nevznikajú žiadne skryté náklady.</p>
                </div>
            </div>
            
           
        </div>
        
    </section>
  )
}

export default HowWeWork